.statusContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  background-color: #f0f2f7;
  border: 1px solid #f0f2f7;
  padding: 0px 6px 0px 12px;
  cursor: pointer;
  position: relative;
}

.statusContainer:focus {
  border: 1px solid #5d36ef !important;
  outline-style: none;
}

.statusContainer.selected {
  border: 1px solid #5d36ef;
}

.moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  transform: rotate(0deg);
}

.moreIcon.open {
  transform: rotate(180deg);
}

.statusValueContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.statusValueIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.statusValueLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  white-space: nowrap;
}

.paper {
  margin-top: 8px !important;
  height: 322px !important;
  border-radius: 26px !important;
  overflow: hidden !important;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
}

.paperAutoHeight {
  height: auto !important;
}

.popoverContent {
  position: relative;
  height: 100%;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  flex: 1;
}

.listWrapper::-webkit-scrollbar {
  width: 8px;
}

.listWrapper::-webkit-scrollbar-track {
  border-radius: 8px;
}

.listWrapper::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 8px;
}

.statusMenuItem {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 46px;
  padding: 0px 16px;
  cursor: pointer;
  border-radius: 8px;
}

.statusMenuItem:hover {
  background-color: var(--palette-neutrals-grayBlue);
}

.statusMenuItem.selected {
  background-color: var(--palette-options-hover) !important;
}

.statusMenuItemIcon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.statusMenuItemLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}
