.header {
  display: flex;
  justify-content: space-between;
}

.workflowConfigPopover {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.workflowConfigBackDrop {
  width: 100%;
  height: 100%;
}

.workflowConfigContainer {
  touch-action: manipulation !important;
  position: absolute;
  box-sizing: border-box;
  top: 68px;
  right: 0;
  background: var(--palette-neutrals-grayBlue);
  width: 610px;
  height: calc(100% - 68px);
  box-shadow: -1px 4px 11px rgba(142, 142, 142, 0.25);
  transition: all 0.3s ease-in-out;
  padding: 30px 16px 0px;
}

.hidden {
  right: -610px;
}

.taskContent {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}
