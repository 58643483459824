.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.priorityWrapper {
  display: flex;
  gap: 40px;
}

.priorityGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: fit-content;
}

.priorityLabel {
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 150% !important;
  letter-spacing: 0.15px;
  color: rgba(8, 25, 73, 0.5) !important;
}

.priorityValue {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue) !important;
}

.descriptionWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.attachmentsWrapper {
  margin-top: 40px;
}

.viewMoreWrapper {
  display: flex;
  align-items: center;
}

.viewMoreLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--palette-primary-purple);
  cursor: pointer;
}

.moreIcon {
  font-size: 24px !important;
  color: var(--palette-secondary-darkBlue);
  cursor: pointer;
}

.lessIcon {
  transform: rotate(180deg);
}
