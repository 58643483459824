.inviteModalContainer {
  width: 564px;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modalTitle {
  justify-content: center !important;
  margin-bottom: 6px;
}

.inputField {
  width: 100%;
}

.category {
  display: flex;
  justify-content: space-between;
  width: 418px !important;
  height: 44px !important;
  border-radius: 58px !important;
  padding: 0px 24px 0px 24px !important;
  background-color: #fff;
}

.row {
  display: flex;
  gap: 10px;
}
