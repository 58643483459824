.createTaskContainer {
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: auto;
  position: relative;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.backButton {
  position: absolute;
  top: 32px;
  left: 24px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 120px);
  min-height: 272px;
  min-width: 550px;
  overflow: hidden;
  padding: 50px 70px;
  width: 100%;
  margin: auto;
  gap: 40px;
}

.dropdown {
  flex: 1;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}

.taskType {
  display: flex;
  justify-content: space-between;
  width: 508px;
  height: 34px !important;
  border-radius: 58px !important;
  border: 1px solid transparent;
  padding: 5px 18px 5px 24px !important;
  background-color: #fff;
}

.typeContentTitle {
  margin-top: 40px !important;
}

.contentTypeWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  cursor: pointer;
  border: 3px solid transparent;
}

.contentTypeWrapper:hover {
  border: 3px solid var(--palette-primary-purple);
}

.contentTypeWrapper.active {
  border: 3px solid var(--palette-primary-purple);
}

.contentTypeIcon {
  width: 48px;
  height: 48px;
}

.contentTypeLabel {
  width: 162px;
  padding-top: 8px;
  text-align: center;
}

.asinWrapper {
  flex: 1;
  margin-top: 40px;
}

.buttonGroup {
  margin-top: 40px;
  gap: 8px;
  display: flex;
}

.accountList {
  margin-top: -8px;
}

.account {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 14px;
  padding-top: 10px;
  overflow: hidden;
  cursor: pointer;
}
