.errorPage {
  height: calc(100vh - 40px - var(--app-banner-height));
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  height: calc(100% - 80px);
  width: calc(100% - 160px);
}

.title {
  max-width: 850px;
  text-align: center;
  margin-top: 32px !important;
}

.copyCodeBlock {
  text-align: center;
  margin-top: 16px;
}

.codeBlock {
  display: flex;
  flex-direction: column;
  width: 850px;
  height: calc(100% - 300px);
  margin-top: 16px !important;
  padding: 16px 24px;
  border-radius: 26px;
  background-color: #fff;
  white-space: pre-line !important;
  overflow-y: auto;
}

.codeBlock::-webkit-scrollbar-track {
  margin-top: 16px;
  margin-bottom: 16px;
}

.errorMessage {
  white-space: pre-line !important;
  overflow-wrap: break-word !important;
}

.actions {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
