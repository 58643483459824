.accountPageContainer {
  width: 100%;
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: auto;
}

.developerSettings {
  width: 750px;
  margin: auto;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
}

.developerHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 65px;
}

.apiDescription {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.receiveAPISwitch {
  position: absolute;
  left: -10px;
  top: 85px;
}

.editButton {
  margin-right: 52px !important;
}

.apiKeyContainer {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 28px;
}

.apiValue {
  width: 672px;
}

.apiKeyContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}

.visibilityIcon {
  cursor: pointer;
  padding-left: 24px;
}

.copyIcon {
  width: 24px;
  height: 24px;
  fill: 'none';
  color: var(--palette-secondary-darkBlue);
}

.copyButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  user-select: none;
  cursor: pointer;
}
