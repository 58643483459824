.createTaskContainer {
  touch-action: manipulation !important;
  position: relative;
  max-width: calc(100% - 140px);
  min-height: 272px;
  min-width: 550px;
  overflow: hidden;
  padding: 50px 70px;
  width: 100%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.backButton {
  padding: 0 14px 0 0 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
}

.contentContainer {
  width: 100%;
  max-width: 960px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  gap: 16px;
}

.contentGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.assignWrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.closeIcon {
  color: var(--palette-secondary-darkBlue);
}

.buttonGroup {
  margin: 24px 0px;
  display: flex;
}

.uploader {
  margin-top: 12px !important;
  width: 100% !important;
}

.dropZone {
  border: 1px dashed #afafaf;
  border-radius: 16px;
}

.estimatedTime {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  gap: 6px;
}

.infoIcon {
  font-size: 13px !important;
}

.assignContainer {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  height: 44px !important;
  border: 1px solid transparent;
  border-radius: 58px !important;
  padding: 0px 12px 0px 18px !important;
  background-color: #fff;
  width: 236px;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}

.closeIcon {
  color: var(--palette-secondary-darkBlue);
}

.buttonGroup {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.requirementsMsg {
  margin-bottom: 12px;
}

.requirementsMsg > span > div {
  width: fit-content;
}

.dropZone {
  border: 1px dashed #afafaf;
  border-radius: 16px;
}

.errorMsgDialog {
  width: fit-content !important;
}

.fullWidth {
  flex: 1 0;
}

.noFlex {
  flex: none;
}

.createTaskTitle {
  display: flex;
  margin-bottom: 15px;
  min-width: 960px;
}

.createTaskTitleText {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 80px;
}

.errorLabel {
  color: var(--palette-ui-error) !important;
}

.warningLabel {
  color: var(--palette-ui-warning) !important;
}

@media (max-width: 1280px) {
  .createTaskTitle {
    min-width: 100%;
  }
}

/* Mobile */
@media (max-width: 768px) {

  .row {
    flex-direction: column;
  }

  .contentGroup {
    margin-bottom: 10px;
  }

  .buttonGroup {
    margin: 10px 0px 20px 0px;
    justify-content: center;
  }
}
