.filtersGroup {
  margin-top: 12px;
  overflow: auto;
  display: flex;
  gap: 12px;
}

.filtersContainer {
  display: flex;
  gap: 10px;
}

.filtersGroup::-webkit-scrollbar {
  height: 0px;
}

.filtersGroup::-webkit-scrollbar-track {
  border-radius: 8px;
}

.filtersGroup::-webkit-scrollbar-thumb {
  background-clip: border-box;
  background: #b8b9bb;
  border-radius: 8px;
  border: 4px solid #f0f2f7;
}

.filterItem {
  display: inline-block;
  white-space: nowrap;
}

.menuItem {
  padding: 4px 0px 4px 20px !important;
}

.searchMembers {
  display: flex;
  justify-content: space-between;
  min-width: 184px;
  width: fit-content;
  height: 34px !important;
  border: 1px solid #d3d3d3;
  border-radius: 58px !important;
  padding: 0px 12px 0px 18px !important;
  background-color: #fff;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}

.leftShadow {
  /* margin-left: 0px; */
  position: absolute;
  width: 60px !important;
  height: 52px;
  background: linear-gradient(270deg, #f0f2f7 0.9%, rgba(240, 242, 247, 0) 99.1%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-top: -8px;
  left: 0px;
  z-index: 99;
}
.rightShadow {
  /* margin-left: 0px; */
  position: absolute;
  width: 60px !important;
  height: 52px;
  background: linear-gradient(270deg, #f0f2f7 0.9%, rgba(240, 242, 247, 0) 99.1%);
  right: 24px;
  margin-top: -8px;
  z-index: 99;
}
