.questionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addQuestionWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

.qaContent {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: auto;
}

.questionTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: var(--palette-secondary-darkBlue);
}

.noQuestionContainer {
  display: flex;
  height: calc(100vh - 312x - var(--app-banner-height));
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.actionButtonWrapper {
  padding: 11px 24px;
  background-color: var(--palette-neutrals-grayBlue);
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(211, 211, 211, 0.4);
}
