.main-page-container {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  touch-action: manipulation !important;
}

.main-page-layout {
  display: flex;
  flex-grow: 1;
  height: calc(100vh - 68px);
  position: relative;
}
