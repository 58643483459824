.modalContainer {
  width: 617px !important;
}

.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px !important;
  gap: 8px;
}

.modalTitle {
  justify-content: center !important;
  margin-bottom: 6px;
}

.description {
  text-align: center !important;
}
