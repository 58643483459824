.notFoundPage {
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: hidden;
  position: relative;
}

.notFoundContainer {
  width: 50%;
  margin: auto;
  padding: 51px 24px;
  display: flex;
  flex-direction: column;
  padding-left: 50%;
}

.sorry {
  font-weight: 300;
  font-size: 95px;
  line-height: 116.7%;
  letter-spacing: -1.5px;
  color: var(--palette-secondary-darkBlue);
}

.errorText {
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: var(--palette-secondary-darkBlue);
  letter-spacing: 0.15px;
  padding-top: 13px;
}

.infoLink {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 160% !important;
  letter-spacing: 0.15px !important;
  color: var(--palette-primary-purple);
  text-decoration: none;
}

.jinxContainer {
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  margin-left: 300px;
}

.jinx {
  font-weight: 300;
  font-size: 48px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: var(--palette-secondary-darkBlue);
}

.catImg {
  width: 707px;
  height: 315px;
  margin-top: 28px;
  margin-left: -380px;
}
