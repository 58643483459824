.accountPageContainer {
  width: 100%;
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: auto;
}

.accountSettings {
  width: 550px;
  margin: auto;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
}

.subPersonalSetting {
  position: relative;
  display: flex;
  align-items: center;
}

.subAccountSetting {
  margin-top: 50px !important;
  position: relative;
  display: flex;
  align-items: center;
}

.edit {
  position: absolute;
  right: 0px;
  cursor: pointer;
  color: var(--palette-primary-purple) !important;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none !important;
}

.editButton {
  color: var(--palette-primary-purple) !important;
}

.editIcon {
  font-size: 16px !important;
}

.editButton:hover,
.edit:hover {
  color: var(--palette-primary-darkPurple) !important;
}

.personalInputContainer {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.divideLine {
  margin-top: 50px;
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #24242433;
}

.contract {
  margin-top: 60px;
  width: 466px;
  padding: 20px 16px 20px 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: white;
  border-left: 5px solid #b39dff;
  border-radius: 4px;
}

.contractGroup {
  display: flex;
  flex-direction: column;
}

.body1Bold {
  font-weight: 700 !important;
}

.info {
  padding-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--palette-secondary-darkBlue);
}

.infoLink {
  font-weight: 700;
  font-size: 16px !important;
  color: var(--palette-primary-purple);
  text-decoration: none;
}
