.accountPageContainer {
  width: 100%;
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  overflow: auto;
}

.organizationSettings {
  width: 750px;
  margin: auto;
  padding: 25px 0px 60px;
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.infoGroupContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.organizationHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 24px;
}

.organizationList {
  display: flex;
  flex-direction: column;
  width: 466px;
}

.button {
  margin-bottom: 2px !important;
  width: 100px;
}

.buttonWrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 2px;
}

.divideLine {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #24242433;
}

.removeIcon {
  position: absolute !important;
  right: -30px;
  top: -16px;
}

.logoContainer {
  width: 706px !important;
  height: 122px !important;
  border-radius: 16px;
  padding-top: 32px;
}

.logoArea {
  background: transparent;
  display: flex;
  background: var(--palette-neutrals-tableLightGray) !important;
  border: none !important;
  border-radius: 16px;
}

.hasFile {
  align-items: flex-start !important;
}

.editingBackground {
  background-color: white !important;
}

.logoImage {
  width: 145px !important;
  height: 30px !important;
  background: transparent !important;
  border: none !important;
  padding-left: 16px;
}

.fromEmailWrapper {
  margin-top: 30px;
  display: flex;
  gap: 16px;
}

.eventSubscriptionWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  background: #ebedf4;
  width: 512px;
}
