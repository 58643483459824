.modalContainer {
  width: 617px !important;
}

.modalContent {
  gap: 10px;
}

.modalTitle {
  justify-content: center !important;
  margin-bottom: 6px;
}

.successContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px !important;
  gap: 8px;
}

.inputField {
  width: 100%;
  margin-bottom: 10px;
}

.category {
  display: flex;
  justify-content: space-between;
  width: 418px !important;
  height: 44px !important;
  border-radius: 58px !important;
  padding: 0px 24px 0px 24px !important;
  background-color: #fff;
}

.threeUsersIcon {
  width: 118px !important;
  height: 84px !important;
  margin-bottom: 16px !important;
}

.description {
  text-align: center !important;
}

.assignContainer {
  display: flex;
  justify-content: space-between;
  width: 313px;
  height: 44px !important;
  border: 1px solid transparent;
  border-radius: 58px !important;
  padding: 0px 12px 0px 18px !important;
  background-color: #fff;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}
