.membersContainer {
  flex: 1;
  height: calc(100vh - var(--app-banner-height));
  touch-action: manipulation !important;
  position: relative;
  overflow: auto;
}

.memberSettings {
  width: 75%;
  height: calc(100% - 48px);
  margin: auto;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
}
