.taskDetail {
  display: flex;
  flex: 1;
  height: calc(100vh - var(--app-banner-height));
}

.detailContent {
  flex: 1;
  display: flex;
  overflow: hidden;
}
