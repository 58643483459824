.filtersGroup {
  margin-top: 15px;
  overflow: hidden;
  display: flex;
  gap: 10px;
  color: var(--palette-secondary-darkBlue);
}

.filtersContainer {
  display: flex;
  gap: 10px;
}

.filtersGroup::-webkit-scrollbar {
  height: 16px;
}

.filtersGroup::-webkit-scrollbar-track {
  border-radius: 8px;
}

.filtersGroup::-webkit-scrollbar-thumb {
  background-clip: border-box;
  background: #b8b9bb;
  border-radius: 8px;
  border: 4px solid #f0f2f7;
}

.filterItem {
  display: inline-block;
  white-space: nowrap;
}

.menuItem {
  padding: 4px 0px 4px 20px !important;
}

.searchMembers {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  height: 34px !important;
  border: 1px solid #d3d3d3;
  border-radius: 58px !important;
  padding: 0px 12px 0px 18px !important;
  background-color: #fff;
}

.moreIcon {
  color: var(--palette-primary-purple) !important;
}

.leftShadow {
  position: sticky;
  left: 0px;
  width: 0px;
  z-index: 2;
}

.leftShadow::after {
  content: ' ';
  position: absolute;
  width: 96px !important;
  height: 52px;
  background: linear-gradient(270deg, #f0f2f7 0.9%, rgba(240, 242, 247, 0) 99.1%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  margin-top: -8px;
  left: 0px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.rightShadow {
  position: sticky;
  right: 0px;
  width: 0px;
  z-index: 2;
}

.rightShadow::after {
  content: ' ';
  position: absolute;
  width: 96px !important;
  height: 52px;
  background: linear-gradient(270deg, #f0f2f7 0.9%, rgba(240, 242, 247, 0) 99.1%);
  right: 0px;
  margin-top: -8px;
  z-index: 99;
  display: flex;
  align-items: center;
}

.arrowIcon {
  position: absolute;
  width: 32px;
  height: 32px;
  border: 2px solid var(--palette-neutrals-lightGrey);
  border-radius: 50%;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowIcon:hover {
  cursor: pointer;
  border: 2px solid var(--palette-primary-purple);
}

.leftArrowIcon {
  margin-left: 0px;
}

.rightArrowIcon {
  margin-left: -36px;
}

.menuItemCheckbox {
  margin-right: 0px !important;
}

.highlight {
  font-weight: 600;
  background-color: var(--palette-secondary-lightPurple) !important;
}

.dateFilter {
  width: 220px;
  height: 36px;
}
