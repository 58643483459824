.detailContentContainer {
  flex: 1;
  overflow: auto hidden;
  position: relative;
  padding-left: 1px;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: auto;
  padding: 20px 24px 16px;
  background-color: #fff;
  box-shadow: inset 0px -1px 0px rgba(211, 211, 211, 0.6);
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.title {
  display: flex;
  flex: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.dropdownWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  z-index: 1;
}

.actionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: auto 0px;
  user-select: none;
  cursor: pointer;
}

.actionIcon {
  width: 24px;
  height: 24px;
  fill: 'none';
  color: var(--palette-secondary-darkBlue);
}

.flexNone {
  flex: none;
}

.secondaryButton {
  font-weight: 700 !important;
  font-size: 14px !important;
  letter-spacing: 0.4px !important;
  line-height: 24px !important;
  padding: 6px 14px !important;
  font-family: 'Open Sans' !important;
  height: 36px;
  border-radius: 26px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  background-color: #ffffff !important;
  color: var(--palette-neutrals-gray) !important;
  border: 2px solid var(--palette-neutrals-lightGrey) !important;
}

.secondaryButton:hover {
  border: 2px solid var(--palette-primary-purple) !important;
}

.cancelDialog {
  width: 617px;
}

.statusValueContainer {
  display: flex !important;
  align-items: center !important;
  gap: 5px;
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  letter-spacing: 0.15px !important;
  height: 26px !important;
  padding: 0px 12px !important;
  border-radius: 30px !important;
  color: var(--palette-secondary-darkBlue) !important;
  background-color: var(--palette-neutrals-grayBlue) !important;
  border: 1px solid var(--palette-neutrals-grayBlue) !important;
}

.statusValueIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.statusValueLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  white-space: nowrap;
}

.iconButton {
  width: 30px !important;
  height: 30px !important;
}
