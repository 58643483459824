.headerContainer {
  display: flex;
  flex-direction: column;
}

.subtitle {
  margin-top: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--palette-secondary-darkBlue);
}

.selectLabel {
  font-family: Open Sans !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: var(--palette--secondary--darkBlue) !important;
  margin-top: 24px !important;
}

.selectLabelDisabled {
  color: var(--palette--neutrals--greyMed) !important;
}

.selectLabelError {
  color: var(--palette--ui--error) !important;
}

.filtersRoot {
  display: flex;
  flex-direction: column;
}

.selectorWrapper {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 12px;
}

.searchRoot {
  height: 36px !important;
}

.searchIcon {
  /* margin-top: 24px; */
  color: var(--palette-secondary-darkBlue);
  cursor: pointer;
}

.chip {
  margin-left: 8px;
}
