.informationWrapper {
  margin-top: 20px;
  display: flex;
  flex: 1, 0;
  flex-direction: column;
  gap: 20px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editingPriorityWrapper {
  display: flex;
  flex: 1 100%;
  gap: 20px;
  overflow: auto;
}

.uploader {
  margin-top: 12px !important;
  width: 100% !important;
}

.dropZone {
  border-radius: 16px;
}

.buttonGroup {
  gap: 12px;
  display: flex;
}
