.detailListContainer {
  overflow-x: hidden;
  overflow-y: auto;
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 1px 0px 0px rgba(211, 211, 211, 0.6);
  position: relative;
  padding-bottom: 20px;
}

.detailListContainer::-webkit-scrollbar {
  width: 6px;
}

.detailListContainer::-webkit-scrollbar-track {
  border-radius: 100px;
  margin-bottom: 4px;
}

.detailListContainer::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 100px;
  border: 0px solid rgba(0, 0, 0, 0.01);
}
