.memberTable {
  display: flex;
  margin-top: 12px;
}

.memberTable {
  flex: 1;
  display: flex;
  margin-top: 12px;
  overflow: auto;
}

.tableContainer {
  width: 100%;
  border-radius: 10px !important;
  overflow: auto;
  background-color: #fff;
  flex: 1;
  max-height: calc(100% - 48px);
}

.tableContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.tableContainer::-webkit-scrollbar-track {
  border-radius: 100px;
  margin-top: 54px;
  margin-bottom: 10px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 100px;
}

.headCell {
  height: 52px;
  padding: 0px !important;
  white-space: nowrap;
  padding: 0px 12px 0px 0px !important;
  border-bottom: 1px solid rgba(211, 211, 211, 0.4) !important;
}

.firstHeadCell {
  padding: 0px 12px 0px 24px !important;
}

.lastHeadCell {
  padding-right: 8px !important;
}

.bodyCell {
  height: 54px;
  background-color: #fff;
  padding: 0px 12px 0px 0px !important;
  border-bottom: 1px solid rgba(211, 211, 211, 0.4) !important;
}

.headerLabel {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.sortIcon {
  width: 24px;
  height: 24px;
  color: var(--palette--secondary--darkBlue) !important;
}

.row {
  background: var(--palette--neutrals--white);
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding-right: 20px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  padding-left: 24px;
  gap: 8px;
}

.emailWrapper {
  display: flex;
  flex-direction: column;
}

.menuItem {
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 10px;
}

.menuItem:hover {
  background: #e8e4f9 !important;
}

.statusCell {
  display: flex;
  align-items: center;
}
