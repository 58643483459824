.taskContent {
  padding: 0px 24px 24px;
  overflow: hidden auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.taskContent::-webkit-scrollbar {
  width: 8px;
}

.taskContent::-webkit-scrollbar-track {
  border-radius: 8px;
}

.taskContent::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 8px;
}

.actionButtonWrapper {
  padding: 11px 24px;
  background-color: var(--palette-neutrals-grayBlue);
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(211, 211, 211, 0.4);
}
