.statusValueContainer {
  display: flex !important;
  align-items: center !important;
  gap: 5px;
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  letter-spacing: 0.15px !important;
  height: 26px !important;
  padding: 0px 12px !important;
  border-radius: 30px !important;
  color: var(--palette-secondary-darkBlue) !important;
  background-color: var(--palette-neutrals-grayBlue) !important;
  border: 1px solid var(--palette-neutrals-grayBlue) !important;
  cursor: default;
}

.statusValueIcon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.statusValueLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  white-space: nowrap;
}
