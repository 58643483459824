.typeaheadV1 {
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  gap: 11px;
}

.typeaheadV1__assignContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 26px;
  border-radius: 30px;
  background-color: #f0f2f7;
  border: 1px solid #f0f2f7;
  padding: 0px 6px 0px 12px;
  cursor: pointer;
  position: relative;
}

.typeaheadV1__assignContainer:focus {
  border: 1px solid #5d36ef !important;
  outline-style: none;
}

.typeaheadV1__assignContainer.selected {
  border: 1px solid #5d36ef;
}

.typeaheadV1__assignTo {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: var(--palette-secondary-darkBlue);
}

.typeaheadV1__assignName {
  font-weight: 700;
}

.typeaheadV1__moreIcon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  color: var(--palette-secondary-darkBlue);
  transform: rotate(0deg);
}

.typeaheadV1__moreIcon.open {
  transform: rotate(180deg);
}

.typeaheadV1__paper {
  margin-top: 8px;
  min-width: 265px;
  height: 300px !important;
  padding: 20px 0px 20px 20px;
  border-radius: 26px !important;
  overflow: hidden !important;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15) !important;
}

.typeaheadV1__popoverContent {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.typeaheadV1__searchMembers {
  margin-right: 20px !important;
}

.typeaheadV1_inputRoot {
  border-radius: 8px !important;
  border: 1px solid rgba(211, 211, 211, 0.6) !important;
}

.typeaheadV1_inputInput {
  height: 22px !important;
  border-radius: 8px !important;
  padding: 9px 12px !important;
  border-color: transparent !important;
}

.typeaheadV1_inputNotchedOutline {
  border-color: transparent !important;
}

.typeaheadV1_inputFocused {
  border: 1px solid var(--palette-primary-purple) !important;
}

.typeaheadV1__listWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
  overflow-y: auto;
  max-height: 250px;
  padding-top: 8px;
}

.typeaheadV1__listWrapper::-webkit-scrollbar {
  width: 16px;
}

.typeaheadV1__listWrapper::-webkit-scrollbar-track {
  border-radius: 100px;
  margin-bottom: 4px;
  margin-top: 8px;
}

.typeaheadV1__listWrapper::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 100px;
  border: 5px solid rgba(0, 0, 0, 0.01);
  min-height: 80px;
}

.typeaheadV1__typeaheadLabel {
  color: #081949 !important;
  font-size: 14px !important;
  transform: none !important;
  font-family: Open Sans !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  cursor: default;
}

.typeaheadV1__tooltip {
  width: 170px !important;
}

.typeaheadV1__errorColor {
  color: #f34e4e !important;
}

.typeaheadV1__errorBorder {
  border: 1px solid #f34e4e !important;
}

.typeaheadV1__noMatchingWrapper {
  margin: auto;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.typeaheadV1__noMatchingIcon {
  color: var(--palette-secondary-darkBlue);
  width: 56px !important;
  height: 56px !important;
  margin: 12px 0px;
}

.typeaheadV1__noMatchingLabel {
  color: var(--palette-neutrals-grayBlueDark);
}

.typeaheadV1__labelWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
}

.typeaheadV1__tooltipIcon {
  font-size: 13px !important;
}
