.caseContent {
  padding: 0px 24px 24px;
  overflow: hidden auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-right: -24px;
  padding: 0px 24px 0px 0px;
  gap: 16px;
}

.caseContent::-webkit-scrollbar {
  width: 8px;
}

.caseContent::-webkit-scrollbar-track {
  border-radius: 8px;
}

.caseContent::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background: #b8b9bb;
  border-radius: 8px;
}

.importingContent {
  align-items: center;
  margin-top: 15%;
}

.statusTitle {
  max-width: 468px !important;
  text-align: center !important;
}

.waitingIcon {
  width: 120px;
  height: 120px;
  min-height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.actionButtonWrapper {
  padding: 11px 24px;
  background-color: var(--palette-neutrals-grayBlue);
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(211, 211, 211, 0.4);
}
