.createTaskPopover {
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.createTaskBackDrop {
  width: 100%;
  height: 100%;
  background-color: rgba(12, 26, 66, 0.6);
}

.createTaskContainer {
  touch-action: manipulation !important;
  position: absolute;
  box-sizing: border-box;
  top: 68px;
  right: 0;
  background: var(--palette-neutrals-grayBlue);
  width: 1000px;
  height: calc(100% - 68px);
  box-shadow: -1px 4px 11px rgba(142, 142, 142, 0.25);
  transition: all 0.3s ease-in-out;
}

.hidden {
  right: -1000px;
}

.taskContent {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 30px;
}

.modalContainer {
  max-width: 1200px !important;
  width: unset !important;
  overflow: hidden;
}

.modalContent {
  padding: 0 !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.modalContentContainer {
  max-height: 80vh;
  width: 100%;
  border-radius: 50px;
  overflow-y: auto;
  padding-right: 20px;
  box-sizing: content-box;
  overflow-x: hidden;
}

.modalContentContainer::-webkit-scrollbar {
  width: 8px;
}

.modalContentContainer::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* Mobile */
@media (max-width: 768px) {
  .modalContainer {
    width: 95% !important;
  }

  .modalContent > div {
    padding: 30px;
    width: calc(100% - 60px);
  }
}

/* Tablet */
@media (min-width: 769px) and (max-width: 1280px) {
  .modalContainer {
    width: 90% !important;
  }

  .modalContent > div {
    padding: 40px 50px;
    width: calc(100% - 100px);
  }
}
